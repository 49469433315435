import React from "react"

import Typography from "@material-ui/core/Typography"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    paddingLeft: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      paddingLeft: theme.spacing(10),
    },
  },
}))

const BusinessHours: React.FC<Record<string, unknown>> = () => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Typography variant="body1" align="left" paragraph={true}>
        Monday to Sunday – 07:00 to 17:00
        <br />
        Public Holidays – 07:00 to 14:00
      </Typography>
    </div>
  )
}

export default BusinessHours
