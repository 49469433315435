/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { PageProps } from "gatsby"
import React from "react"

import Grid from "@material-ui/core/Grid"
import { makeStyles } from "@material-ui/core/styles"

import BusinessAddress from "../components/business-address"
import BusinessHours from "../components/business-hours"
import FullName from "../components/full-name"
import HeroImage from "../components/hero-image"
import Profile from "../components/profile"
import SEO from "../components/seo"
import SpecializationIcon from "../components/specialization-icon"
import familyIcon from "../images/family-icon.png"
import sportsIcon from "../images/sports-icon.png"

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
  },
  specializationIcon: {
    margin: "0 auto",
  },
}))

// eslint-disable-next-line @typescript-eslint/ban-types
type DataProps = {}

const IndexPage: React.FC<PageProps<DataProps>> = () => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <SEO title="Home" />
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <HeroImage />
        </Grid>
        <Grid item xs={12} md={6}>
          <FullName />
          <Profile />
          <Grid
            item
            container
            xs={12}
            justify="center"
            alignItems="center"
            spacing={2}
          >
            <Grid item xs={12} sm={6}>
              <SpecializationIcon
                imgSrc={familyIcon}
                title="Family Medicine"
                className={classes.specializationIcon}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <SpecializationIcon
                imgSrc={sportsIcon}
                title="Sports Medicine"
                className={classes.specializationIcon}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <BusinessAddress />
          <BusinessHours />
        </Grid>
      </Grid>
    </div>
  )
}

export default IndexPage
