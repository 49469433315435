import React from "react"

import Typography from "@material-ui/core/Typography"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(8),
    paddingRight: theme.spacing(8),
    marginBottom: theme.spacing(8),
  },
}))

const Profile: React.FC<Record<string, unknown>> = () => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Typography variant="body1" align="center" paragraph={true}>
        Dedicated and experienced medical professional with extensive knowledge
        and specialization in Family and Sports Medicine.
      </Typography>
      <Typography variant="subtitle2" align="center">
        MD, MMed (Family Medicine), Pretoria, MSc (Sports Medicine), Pretoria
      </Typography>
    </div>
  )
}

export default Profile
