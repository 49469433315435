import React from "react"

import Button from "@material-ui/core/Button"
import Typography from "@material-ui/core/Typography"
import { makeStyles } from "@material-ui/core/styles"
import RoomIcon from "@material-ui/icons/Room"

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    paddingLeft: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      paddingLeft: theme.spacing(10),
    },
  },
  button: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}))

const BusinessAddress: React.FC<Record<string, unknown>> = () => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Typography variant="h6">HEALth-WorX Lynnwood</Typography>
      <Typography variant="body1" align="left" paragraph={true}>
        Shop S201, Lynnwood Lane Retail Centre,
        <br />
        c/o Lynnwood Drive & Equestria Road, Equestria,
        <br />
        Pretoria, 0184
      </Typography>
      <Button
        variant="contained"
        color="primary"
        startIcon={<RoomIcon />}
        className={classes.button}
        href="https://goo.gl/maps/wq1ujdRcscK1EVsP8"
        target="_blank"
      >
        Get directions
      </Button>
    </div>
  )
}

export default BusinessAddress
