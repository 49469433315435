import clsx from "clsx"
import React from "react"

import Avatar from "@material-ui/core/Avatar"
import Typography from "@material-ui/core/Typography"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  root: {
    width: theme.spacing(24),
  },
  avatar: {
    backgroundColor: "#ffffff",
    width: theme.spacing(24),
    height: theme.spacing(24),
    padding: theme.spacing(6),
    marginBottom: theme.spacing(2),
  },
  img: {
    objectFit: "contain",
  },
}))

interface Props {
  imgSrc: string
  title?: string
  className?: string
}

const SpecializationIcon: React.FC<Props> = ({
  imgSrc,
  title,
  className,
}: Props) => {
  const classes = useStyles()

  return (
    <div className={clsx(classes.root, className)}>
      <Avatar
        src={imgSrc}
        alt={title}
        className={classes.avatar}
        classes={{
          img: classes.img,
        }}
      />
      <Typography variant="h6" align="center">
        {title}
      </Typography>
    </div>
  )
}

export default SpecializationIcon
