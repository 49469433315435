import React from "react"

import Typography from "@material-ui/core/Typography"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(4),
  },
  title: {},
  firstName: {
    color: "#ea775d",
    fontFamily: "LaLuxesScript",
    fontSize: "16em",
    [theme.breakpoints.up("md")]: {
      position: "relative",
      marginTop: "-16%",
      marginLeft: "10%",
    },
  },
  lastName: {
    [theme.breakpoints.up("md")]: {
      position: "relative",
      marginTop: "-12%",
      marginLeft: "35%",
    },
  },
}))

const FullName: React.FC<Record<string, unknown>> = () => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Typography variant="h1" className={classes.title}>
        Dr.
      </Typography>
      <Typography variant="h1" className={classes.firstName}>
        Elena
      </Typography>
      <Typography variant="h1" className={classes.lastName}>
        Pavlova
      </Typography>
    </div>
  )
}

export default FullName
